.mpk-data-table{
  position: relative;
  width: 100%;
  .rmd-table{
    background: white;
  }
  &.fit{
    height: 100%;
  }
  .fit{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .numeric{
    text-align: left;
  }
  .rmd-table-cell--sticky{
    will-change: auto;
    position: relative;
    .rmd-menu{
      position: fixed !important;
      // display: block;
      // left: 140px !important;
      // transform-origin: 0 !important;
      z-index: 24;
    }
  }
  .main-loader{
    position: absolute;
    width: 100%;
    top: 0;
    left:0;
    z-index: 5;
    .rmd-linear-progress{
      height: 2px;
      background-color: #fafafa;
    }
  }
  .pagination-container{
    height: 48px;
    width: 100%;
  }
}