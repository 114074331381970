/* [1] The container */
.img-hover-zoom {
    // height: 300px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    
  }
  
  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    transition: transform .5s ease;
  }
  
  /* [3] Finally, transforming the image when container gets hovered */
  .img-hover-zoom:hover img {
    transform: scale(1.5);
  }