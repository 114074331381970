@import '../../styles/var';

.mpk-data-view-summary{
  .trend{
    &.up{
      color: $mpk-color-success;
      .rmd-icon{
        color: inherit;
      }
    }
    &.down{
      color: $mpk-color-warn;
      .rmd-icon{
        color: inherit
      }
    }
  }
}