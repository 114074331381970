.Container{
    max-width: 800px;
    min-width: 500px;
    height: auto
}
.Row{
    display: flex;
    flex-direction: row;  
}
.Column{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.TwoColumn{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.Title{
    font-size: 10pt;
    margin-bottom: -20px;
    color: #3c6382,
}
.SubTitle{
    font-size: 12pt;
    font-weight: bold;
    color: #0c2461,
}

